import React, { memo } from 'react'
import { IconProps } from '~icons'

const Plus: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg
      className={'fill-current ' + className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M9.4 9.5V0H15.7V9.5H25.1V15.8H15.7V25.2H9.4V15.8H0V9.5H9.4Z" fill=""/>
    </svg>
  )
}

export default memo(Plus)
