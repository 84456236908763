import React, { useEffect, useMemo, useState } from 'react';

import { Button, Card, Input, PollListItem, Range } from '~elements';
import { Logo } from '~icons';

interface MortgageParamsType {
  propertyValue: number | null;
  borrowValue: number | null;
  borrowYears: number;
}

const getLTVPercent = (percentBorrowValueByPropertyValue?: number) => {
  if (!percentBorrowValueByPropertyValue) return 0;
  if (percentBorrowValueByPropertyValue <= 60) {
    return 0.0079;
  }
  if (percentBorrowValueByPropertyValue <= 70) {
    return 0.0089;
  }
  if (percentBorrowValueByPropertyValue <= 75) {
    return 0.0092;
  }
  if (percentBorrowValueByPropertyValue <= 80) {
    return 0.0139;
  }
  if (percentBorrowValueByPropertyValue <= 85) {
    return 0.0153;
  }
  if (percentBorrowValueByPropertyValue <= 90) {
    return 0.0189;
  }
  if (percentBorrowValueByPropertyValue <= 95) {
    return 0.0229;
  }
};

const calculateMonthlyMortgage = (
  borrow: number,
  LTV: number,
  years: number
) => {
  const months = years * 12;
  return (
    (((borrow * LTV) / 12) * Math.pow(LTV / 12 + 1, months)) /
    (Math.pow(LTV / 12 + 1, months) - 1)
  );
};
// 12124123.12
const format = (value: string): string => {
  const [integerValue, decimalValue = ''] = value.split('.');
  const formattedValue = integerValue.replaceAll(/[0-9]/g, (letter, index) => {
    // every 3 letter
    const reversedIndex = integerValue.length - index - 1;
    const isMultipleOfThreeLetter = (reversedIndex + 1) % 3 === 0;
    const isLastLetter = reversedIndex + 1 === integerValue.length;
    if (isMultipleOfThreeLetter && !isLastLetter) {
      return ',' + letter;
    }
    return letter;
  });
  return `${formattedValue}.${decimalValue}` || '0';
};

const Home: React.FC = () => {
  const [mortgageParams, setMortgageParams] = useState<MortgageParamsType>({
    propertyValue: null,
    borrowValue: null,
    borrowYears: 20,
  });
  const [monthlyMortgage, setMonthlyMortgage] = useState<string | null>(null);

  useEffect(
    () => handleCalculateMonthlyMortgage(),
    [
      mortgageParams.propertyValue,
      mortgageParams.borrowValue,
      mortgageParams.borrowYears,
    ]
  );

  const handleChangeMortgageParams =
    (field: keyof MortgageParamsType) => (value: string) => {
      const valueAsNumber = +value;
      setMortgageParams({
        ...mortgageParams,
        [field]: valueAsNumber || null,
      });
    };

  const validateMortgageParams = () => {
    const { borrowValue, propertyValue } = mortgageParams;
    const isPropertyValueExist = !!propertyValue;
    const isBorrowValueExist = !!borrowValue;
    let isValidMortgageParams = isPropertyValueExist && isBorrowValueExist;

    if (isPropertyValueExist && isBorrowValueExist) {
      const MAX_PROPERTY_PERCENT = 0.95;
      isValidMortgageParams =
        isBorrowValueExist &&
        borrowValue >= 10000 &&
        borrowValue <= propertyValue * MAX_PROPERTY_PERCENT;
    }

    return isValidMortgageParams;
  };

  const percentBorrowValueByPropertyValue = useMemo(() => {
    const { borrowValue, propertyValue } = mortgageParams;

    if (borrowValue && propertyValue) {
      return (borrowValue / propertyValue) * 100;
    }
  }, [mortgageParams.borrowValue, mortgageParams.propertyValue]);

  const handleCalculateMonthlyMortgage = () => {
    const { borrowValue, borrowYears } = mortgageParams;

    const isValidMortgageParams = validateMortgageParams();
    const LTVPercent = getLTVPercent(percentBorrowValueByPropertyValue);

    if (isValidMortgageParams && LTVPercent) {
      const newMonthlyMortgage = calculateMonthlyMortgage(
        borrowValue!,
        LTVPercent,
        borrowYears
      );
      const formattedMonthlyMortgage = format(
        newMonthlyMortgage.toFixed(2).toString()
      );
      setMonthlyMortgage(formattedMonthlyMortgage);
    } else {
      setMonthlyMortgage(null);
    }
  };

  return (
    <section className={'pt-46 pb-83 px-50 sm:pb-60 sm:pt-40 sm:px-16'}>
      <div className={'flex flex-row md:flex-col'}>
        <Card
          className={
            'mr-0 w-50% mr-33 md:w-100% md:mr-0 md:mb-28 sm:pt-34 sm:px-15 sm:pb-43'
          }
        >
          <div className={'flex items-center mb-38'}>
            <Logo className={'mr-26 sm:w-54 sm:h-48 sm:mr-16'} />
            <h1 className={'text-mid-blue sm:text-22'}>About Your Mortgage</h1>
          </div>
          <ul>
            <PollListItem label={'What is the property value?'}>
              <Input
                type={'number'}
                onChange={handleChangeMortgageParams('propertyValue')}
              />
            </PollListItem>
            <PollListItem label={'How much would you like to borrow?'}>
              <Input
                type={'number'}
                onChange={handleChangeMortgageParams('borrowValue')}
              />
            </PollListItem>
            <PollListItem
              label={'How many years do you want to borrow the money for?'}
            >
              <Range
                className={'mt-20'}
                defaultValue={20}
                onChange={handleChangeMortgageParams('borrowYears')}
                min={5}
                max={35}
              />
            </PollListItem>
          </ul>
        </Card>
        <Card
          className={
            'mr-20 w-50% bg-blue-gradient text-white text-center md:w-100% md:mr-0 sm:pt-46 sm:px-25'
          }
        >
          <h2 className={'mb-32 font-bold sm:text-22 sm:mb-10'}>
            Based on your details, your monthly mortgage repayment could be:
          </h2>
          <div className={'mb-40 text-64 font-bold'}>
            £{monthlyMortgage || '0'}
          </div>
          <Button
            url={'https://www.fluentmoney.co.uk/mortgages/mortgage-quote/'}
            className={'mb-51 sm:text-16 sm:mb-30'}
          >
            Get a Quote
          </Button>
          <div className={'text-14'}>
            This calculator is an estimation of how much you may have to repay
            each month. If you’re ready to take out a mortgage, speak to a
            Fluent adviser to see what options are available.
          </div>
        </Card>
      </div>
    </section>
  );
};

export default Home;
