import React, { ReactElement, useEffect, useState } from 'react'

export type IconProps = {
  className?: string
  onClick?(e: React.MouseEvent<HTMLOrSVGElement>): void
}

export type IconType =
  | 'Minus'
  | 'Plus'
  | 'Logo'

import Minus from './Minus'
import Plus from './Plus'
import Logo from './Logo'

const Dynamic = (props: { icon: IconType } & IconProps) => {
  const { icon, ...otherProps } = props

  const [Icon, setIcon] = useState<ReactElement>()
  useEffect(() => {
    import(`./${icon}`).then(res => {
      setIcon(res.default)
    })
  }, [])

  return Icon ? React.createElement(Icon.type, otherProps) : null
}

export {
  Minus,
  Plus,
  Logo,
  Dynamic
}
