import React, { memo } from 'react'
import { IconProps } from "./EXPORT"

const Logo: React.FC<IconProps> = props => {
  const { className } = props

  return (
    <svg width="105" height="95" viewBox="0 0 105 95" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path opacity="0.89" d="M42.9131 94.0438C19.2129 94.0438 8.39233e-05 74.6266 8.39233e-05 50.6743C8.39233e-05 26.7219 19.2129 7.30469 42.9131 7.30469C66.6133 7.30469 85.8262 26.7219 85.8262 50.6743C85.8262 74.6266 66.6133 94.0438 42.9131 94.0438Z" fill="url(#paint0_linear)"/>
      <path opacity="0.73" d="M84 42C72.402 42 63 32.598 63 21C63 9.40202 72.402 0 84 0C95.598 0 105 9.40202 105 21C105 32.598 95.598 42 84 42Z" fill="#1CA6E0"/>
      <path d="M37.102 44.8106H24.5795C24.3073 44.8106 24.0577 44.6747 23.8989 44.4483C23.7401 44.2218 23.6948 43.9501 23.7855 43.6783L27.0069 34.3484C27.1203 34.0087 27.4379 33.7822 27.8009 33.7822H42.297C42.6373 33.7822 42.9322 33.986 43.0683 34.3031C43.2044 34.6201 43.1363 34.9824 42.8868 35.2089L37.9413 40.1909V43.9501C37.9413 44.4256 37.5557 44.8106 37.102 44.8106ZM25.7592 43.1348H36.2626V39.8739C36.2626 39.6474 36.3534 39.4436 36.5122 39.2851L40.278 35.4806H28.3907L25.7592 43.1348Z" fill="white"/>
      <path d="M60.0147 44.8104H47.4923C47.0386 44.8104 46.6529 44.4255 46.6529 43.9725V40.2134L41.7074 35.2314C41.4579 34.9823 41.3898 34.6199 41.526 34.3255C41.6621 34.0085 41.957 33.8047 42.2973 33.8047H56.7934C57.1563 33.8047 57.4739 34.0311 57.5874 34.3708L60.8087 43.7008C60.8995 43.9499 60.8541 44.2443 60.6953 44.4707C60.5365 44.6745 60.287 44.8104 60.0147 44.8104ZM48.3316 43.1347H58.8351L56.2036 35.4805H44.3163L48.0821 39.2849C48.2409 39.4434 48.3316 39.6472 48.3316 39.8737V43.1347Z" fill="white"/>
      <path d="M43.0454 65.8482H27.0747C26.621 65.8482 26.2354 65.4632 26.2354 65.0103V43.9727C26.2354 43.5197 26.621 43.1348 27.0747 43.1348C27.5284 43.1348 27.9141 43.5197 27.9141 43.9727V64.1725H43.0454C43.4991 64.1725 43.8848 64.5574 43.8848 65.0103C43.8848 65.4632 43.4991 65.8482 43.0454 65.8482ZM57.5416 53.8008C57.0878 53.8008 56.7022 53.4158 56.7022 52.9629V43.9727C56.7022 43.5197 57.0878 43.1348 57.5416 43.1348C57.9953 43.1348 58.3809 43.5197 58.3809 43.9727V52.9629C58.3809 53.4158 57.9953 53.8008 57.5416 53.8008Z" fill="white"/>
      <path d="M42.2974 43.6787C40.8909 43.6787 39.7793 42.569 39.7793 41.165C39.7793 39.761 40.8909 38.6514 42.2974 38.6514C43.7039 38.6514 44.8155 39.761 44.8155 41.165C44.8155 42.569 43.7039 43.6787 42.2974 43.6787ZM42.2974 40.3045C41.821 40.3045 41.458 40.6668 41.458 41.1424C41.458 41.6179 41.821 41.9802 42.2974 41.9802C42.7738 41.9802 43.1368 41.6179 43.1368 41.1424C43.1368 40.6668 42.7738 40.3045 42.2974 40.3045Z" fill="white"/>
      <path d="M34.2887 59.0777H29.8423C29.3886 59.0777 29.0029 58.6928 29.0029 58.2398V49.5439C29.0029 49.091 29.3886 48.7061 29.8423 48.7061H34.2887C34.7424 48.7061 35.1281 49.091 35.1281 49.5439V58.2398C35.1281 58.6928 34.7424 59.0777 34.2887 59.0777ZM30.659 57.402H33.4267V50.3818H30.659V57.402Z" fill="white"/>
      <path d="M50.3052 55.2733C49.8515 55.2733 49.4658 54.8883 49.4658 54.4354V49.5439C49.4658 49.091 49.8515 48.7061 50.3052 48.7061H54.7515C55.2053 48.7061 55.5909 49.091 55.5909 49.5439V53.054C55.5909 53.5069 55.2053 53.8919 54.7515 53.8919C54.2978 53.8919 53.9122 53.5069 53.9122 53.054V50.3818H51.1446V54.4354C51.1446 54.8883 50.7816 55.2733 50.3052 55.2733Z" fill="white"/>
      <path d="M37.9868 65.8487C37.5331 65.8487 37.1475 65.4638 37.1475 65.0109V49.5439C37.1475 49.091 37.5331 48.7061 37.9868 48.7061H46.5847C47.0384 48.7061 47.4241 49.091 47.4241 49.5439V57.2434C47.4241 57.6963 47.0384 58.0813 46.5847 58.0813C46.131 58.0813 45.7453 57.6963 45.7453 57.2434V50.3818H38.8262V64.9882C38.8262 65.4638 38.4632 65.8487 37.9868 65.8487Z" fill="white"/>
      <path d="M56.5442 77.2619C50.6006 77.2619 45.7686 72.4384 45.7686 66.5053C45.7686 60.5948 50.6006 55.7939 56.5442 55.7939C62.4879 55.7939 67.3199 60.5948 67.3199 66.5053C67.3199 72.4158 62.4879 77.2619 56.5442 77.2619ZM56.5442 57.4471C51.5307 57.4471 47.4473 61.5006 47.4473 66.4826C47.4473 71.4873 51.5307 75.5635 56.5442 75.5635C61.5578 75.5635 65.6412 71.4873 65.6412 66.4826C65.6412 61.5006 61.5578 57.4471 56.5442 57.4471Z" fill="white"/>
      <path d="M53.6626 71.9172C53.5038 71.9172 53.3677 71.8719 53.2089 71.7813C52.8233 71.5322 52.6872 71.0114 52.9367 70.6264L58.6308 61.5002C58.8803 61.1152 59.4021 60.9794 59.7878 61.2285C60.1734 61.4776 60.3095 61.9984 60.06 62.3834L54.3886 71.5096C54.2298 71.7813 53.9576 71.9172 53.6626 71.9172Z" fill="white"/>
      <path d="M53.3215 66.2337C52.0057 66.2337 50.9395 65.0109 50.9395 63.5163C50.9395 62.0217 52.0057 60.7988 53.3215 60.7988C54.6372 60.7988 55.7035 62.0217 55.7035 63.5163C55.7035 65.0109 54.6372 66.2337 53.3215 66.2337ZM53.3215 62.4746C53.0039 62.4746 52.6182 62.9049 52.6182 63.5163C52.6182 64.1277 52.9812 64.558 53.3215 64.558C53.6391 64.558 54.0247 64.1277 54.0247 63.5163C54.0247 62.9049 53.6617 62.4746 53.3215 62.4746Z" fill="white"/>
      <path d="M59.6964 72.2123C58.3807 72.2123 57.3145 70.9894 57.3145 69.4948C57.3145 68.0002 58.3807 66.7773 59.6964 66.7773C61.0576 66.7773 62.1238 67.9776 62.1238 69.4948C62.1238 71.0121 61.0576 72.2123 59.6964 72.2123ZM59.6964 68.4531C59.3788 68.4531 58.9932 68.8834 58.9932 69.4948C58.9932 70.1062 59.3562 70.5365 59.6964 70.5365C60.1048 70.5365 60.4451 70.0609 60.4451 69.4948C60.4451 68.9287 60.1275 68.4531 59.6964 68.4531Z" fill="white"/>
      <path d="M39.2799 58.5342H37.9868C37.5331 58.5342 37.1475 58.1492 37.1475 57.6963C37.1475 57.2434 37.5331 56.8584 37.9868 56.8584H39.2799C39.7336 56.8584 40.1192 57.2434 40.1192 57.6963C40.1192 58.1492 39.7336 58.5342 39.2799 58.5342Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="42.9136" y1="94.0459" x2="42.9136" y2="7.30681" gradientUnits="userSpaceOnUse">
          <stop stopColor="#921D80"/>
          <stop offset="1" stopColor="#9F4EB0"/>
        </linearGradient>
      </defs>
    </svg>

  )
}

export default memo(Logo)
