import React, { memo } from 'react'
import { IconProps } from '~icons'

const Minus: React.FC<IconProps> = props => {
  const { className, ...otherProps } = props

  return (
    <svg
      className={'fill-current ' + className}
      width="22"
      height="6"
      viewBox="0 0 22 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M0 0H22V6H0V0Z" fill=""/>
    </svg>
)
}

export default memo(Minus)
